import debounce from "lodash.debounce";
import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import { formatMoney, isPositiveNumber, isEmpty } from "@helpers";
import { MIN_AMOUNT_LIMIT } from "@config/base";
import { CardActions } from "@store/modules/card/types";
import { WalletGetters } from "@store/modules/wallet/types";
import { cardModule, walletModule } from "@store/namespaces";
import { Currency } from "@/types/currency";
import { VForm } from "@/types/vuetify";

@Component
export default class CardSetLimitForm extends Vue {
  @walletModule.Getter("wallet")
  private readonly walletGetter!: WalletGetters["wallet"];
  @cardModule.Action("updateCard")
  private readonly updateCardAction!: CardActions["updateCard"];

  @Ref("form") private readonly formRef!: VForm;
  @Prop({ type: String, required: true })
  private readonly cardCurrency!: Currency;
  @Prop({ type: Number, required: true })
  private readonly cardBankId!: number;
  @Prop({ type: Number, required: true })
  private readonly cardId!: number;
  @Prop({ type: Number })
  private readonly dailyLimit?: number;
  @Prop({ type: Number, default: 0 })
  private readonly dailySpend!: number;
  @Prop({ type: Number })
  private readonly totalLimit?: number;
  @Prop({ type: Number, default: 0 })
  private readonly totalSpend!: number;

  private localTotalLimit = "";
  private localDailyLimit = "";
  private loading = false;
  private noDailyLimit = false;
  private noTotalLimit = false;

  private get title() {
    return this.$vuetify.lang.t("$vuetify.dashboard.set_limit");
  }

  private get minLimitAmount() {
    return MIN_AMOUNT_LIMIT;
  }

  private get calcDailyLimit() {
    return this.noDailyLimit ? this.calcTotalLimit : +this.localDailyLimit;
  }

  private get calcTotalLimit() {
    return this.noTotalLimit ? this.currentBalance : +this.localTotalLimit;
  }

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
      positiveNumber: (v: string) => {
        if (!isPositiveNumber(v)) {
          return this.$vuetify.lang.t("$vuetify.errors.positive_number");
        }

        return true;
      },
      minLimitAmount: (v: string) => {
        return (
          +v >= this.minLimitAmount ||
          this.$vuetify.lang.t(
            "$vuetify.errors.min_amount",
            formatMoney({
              value: this.minLimitAmount,
              currency: this.cardCurrency,
            })
          )
        );
      },
      dailLimit: () => {
        if (this.noDailyLimit) return true;

        const dailyLimit = this.calcDailyLimit;

        if (dailyLimit > this.currentBalance + this.dailySpend) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.card.max_limit_you_can_set",
            formatMoney({
              value: this.currentBalance + this.dailySpend,
              currency: this.cardCurrency,
            })
          );
        }

        return true;
      },
      totalLimit: () => {
        if (this.noTotalLimit) return true;

        const totalLimit = this.calcTotalLimit;

        if (totalLimit > this.currentBalance + this.totalSpend) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.card.max_limit_you_can_set",
            formatMoney({
              value: this.currentBalance + this.totalSpend,
              currency: this.cardCurrency,
            })
          );
        }

        const dailyLimit = this.calcDailyLimit;

        if (totalLimit < dailyLimit) {
          return this.$vuetify.lang.t("$vuetify.errors.card.total_daily_limit");
        }

        return true;
      },
    };
  }

  private get currentBalance() {
    return this.walletGetter(this.cardCurrency).balance;
  }

  private validateForm() {
    return this.formRef.validate();
  }

  private async onSubmitForm() {
    if (this.loading || !this.validateForm()) return;

    this.loading = true;

    try {
      await this.updateCardAction({
        id: this.cardId,
        noDailyLimit: this.noDailyLimit || undefined,
        noTotalLimit: this.noTotalLimit || undefined,
        dailyLimit: !this.noDailyLimit ? +this.localDailyLimit : undefined,
        totalLimit: !this.noTotalLimit ? +this.localTotalLimit : undefined,
      });

      this.loading = false;

      this.$emit("success");
    } catch (error) {
      this.loading = false;
    }
  }

  private onClickClose() {
    this.$emit("close");
  }

  private initWatcherDepsForValidate() {
    const watchCallback = debounce(() => {
      this.validateForm();
    }, 100);

    this.$watch(() => {
      return [
        this.calcDailyLimit,
        this.calcTotalLimit,
        this.noDailyLimit,
        this.noTotalLimit,
      ].join("_");
    }, watchCallback);

    this.$once("hook:beforeDestroy", () => {
      watchCallback.cancel();
    });
  }

  private created() {
    this.noDailyLimit = typeof this.dailyLimit !== "number";
    this.noTotalLimit = typeof this.totalLimit !== "number";
    this.localDailyLimit =
      this.dailyLimit?.toString() ?? this.calcDailyLimit.toString();
    this.localTotalLimit =
      this.totalLimit?.toString() ?? this.calcTotalLimit.toString();
  }

  private mounted() {
    this.initWatcherDepsForValidate();
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._t("title",null,{"title":_vm.title}),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}}},[_c('FormField',{staticClass:"mb-4",attrs:{"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.fields.daily_limit"))+" ")]},proxy:true}])},[_c('v-text-field',{staticClass:"autoheight-details",attrs:{"value":_vm.noDailyLimit ? '∞' : _vm.localDailyLimit,"rules":_vm.noDailyLimit
            ? [_vm.fieldRules.dailLimit]
            : [
                _vm.fieldRules.required,
                _vm.fieldRules.positiveNumber,
                _vm.fieldRules.minLimitAmount,
                _vm.fieldRules.dailLimit,
              ],"readonly":_vm.noDailyLimit,"outlined":"","dense":"","inputmode":"decimal"},on:{"input":function($event){_vm.localDailyLimit = $event}}}),_c('v-checkbox',{staticClass:"my-0 pt-0 ml-2",attrs:{"hide-details":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-caption text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.fields.no_daily_limit"))+" ")])]},proxy:true}]),model:{value:(_vm.noDailyLimit),callback:function ($$v) {_vm.noDailyLimit=$$v},expression:"noDailyLimit"}})],1),_c('FormField',{staticClass:"mb-4",attrs:{"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.fields.total_limit"))+" ")]},proxy:true}])},[_c('v-text-field',{staticClass:"autoheight-details",attrs:{"value":_vm.noTotalLimit ? '∞' : _vm.localTotalLimit,"rules":_vm.noTotalLimit
            ? [_vm.fieldRules.totalLimit]
            : [
                _vm.fieldRules.required,
                _vm.fieldRules.positiveNumber,
                _vm.fieldRules.minLimitAmount,
                _vm.fieldRules.totalLimit,
              ],"readonly":_vm.noTotalLimit,"outlined":"","dense":"","inputmode":"decimal"},on:{"input":function($event){_vm.localTotalLimit = $event}}}),_c('v-checkbox',{staticClass:"my-0 pt-0 ml-2",attrs:{"hide-details":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-caption text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.fields.no_total_limit"))+" ")])]},proxy:true}]),model:{value:(_vm.noTotalLimit),callback:function ($$v) {_vm.noTotalLimit=$$v},expression:"noTotalLimit"}})],1),_c('div',{staticClass:"mt-2"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"ripple":false,"block":"","type":"submit","color":"primary","height":"40"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.set"))+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"ripple":false,"outlined":"","block":"","type":"button","color":"error","height":"40"},on:{"click":_vm.onClickClose}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.close"))+" ")])],1)],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }